<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.training.training_requests") }}
          <v-spacer />
          <id-button class="mr-2" @click="onCreate">
            <template #icon>
              <v-icon left>mdi-plus</v-icon>
            </template>
            {{ $t("hr.training.request_training") }}
          </id-button>
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-expand
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template #item="row">
            <tr :active="row.selected">
              <td>
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    size="30"
                    class="white--text body-1 font-weight-medium"
                    :color="row.item.training.color"
                  >
                    {{ row.item.training.name.substring(0, 2).toUpperCase() }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="body-1">
                      {{ row.item.training.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td>
                {{ row.item.requestDateTime | formattedDate }}
              </td>
              <td>{{ row.item.detail || "-" }}</td>
              <td>
                <request-status-label :status="row.item.trainingRequestStatus" />
              </td>
              <td class="text-center">
                <v-icon :color="row.item.trainingPlanned ? 'green' : 'red'">
                  {{ row.item.trainingPlanned ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </td>
              <td class="text-center">
                <v-btn
                  :disabled="isRequestCancelButtonDisabled(row.item.trainingRequestStatus)"
                  small
                  color="primary"
                  depressed
                  @click.stop="openRequestCancelDialog(row.item.id)"
                >
                  <v-icon left>
                    mdi-calendar-remove
                  </v-icon>
                  <span class="font-weight-bold">
                    {{ $t("hr.training.cancel_request") }}
                  </span>
                </v-btn>
              </td>
              <td class="text-right">
                <v-btn icon @click.stop.prevent="row.expand(!row.isExpanded)">
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <v-card color="grey lighten-4">
                  <div v-if="item.abolisher">
                    <v-card-title class="pb-0 title">{{ $t("hr.training.abolisher") }}</v-card-title>
                    <v-card-text>
                      <v-card flat>
                        <v-data-table
                          hide-default-footer
                          :headers="table.abolisherHeaders"
                          :items="convertAbolisherData(item)"
                        >
                          <template #item="abolisher">
                            <tr>
                              <td>
                                <employee-profile :employee="abolisher.item.employee" />
                              </td>
                              <td>
                                {{ abolisher.item.reason || "-" }}
                              </td>

                              <td>
                                {{ abolisher.item.eventDateTime | formattedDateTimeMinute }}
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-card-text>
                  </div>
                  <v-card-title class="pb-0 title">{{ $t("hr.training.approval_history") }}</v-card-title>
                  <v-card-text>
                    <v-data-table
                      hide-default-footer
                      :headers="table.subHeaders"
                      :items="item.trainingRequestProcesses"
                      item-key="id"
                    >
                      <template #item="process">
                        <tr>
                          <td>
                            <employee-profile :employee="process.item.approver" />
                          </td>
                          <td>
                            <request-status-label :status="getStatus(process.item)" />
                          </td>
                          <td>
                            {{ process.item.note || "-" }}
                          </td>
                          <td>
                            {{ process.item.eventDateTime | formattedDateTimeMinute }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
        <request-cancel-dialog v-model="requestCancelDialogStatus" :request-id="requestId" @success="onRefreshData" />
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { TRAINING_REQUESTS } from "./query";

  export default {
    name: "TrainingRequests",
    components: {
      RequestStatusLabel: () => import("@/components/global/request-status-label"),
      RequestCancelDialog: () => import("./components/RequestCancelDialog")
    },
    data: vm => ({
      requestCancelDialogStatus: false,
      requestId: null,
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [vm.$store.state.auth.user.id]
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ["requestDateTime"],
          sortDesc: [true]
        },
        totalItems: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.training.training"),
            value: null,
            align: "start",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.request_date"),
            value: "requestDateTime",
            align: "start",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.detail"),
            value: "",
            align: "start",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.status"),
            value: "trainingRequestStatus",
            align: "start",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.training.training_planned"),
            value: null,
            align: "center",
            sortable: false,
            class: ["primary--text"]
          },
          {
            align: "end",
            sortable: false,
            width: "1%"
          },
          {
            align: "end",
            sortable: false,
            width: "1%"
          }
        ],
        subHeaders: [
          {
            text: vm.$t("hr.training.hr_manager"),
            align: "start",
            sortable: false,
            value: null,
            class: ["primary--text"],
            width: "30%"
          },
          {
            text: vm.$t("global.status"),
            align: "start",
            sortable: false,
            value: null,
            class: ["primary--text"],
            width: "20%"
          },
          {
            text: vm.$t("global.description"),
            align: "start",
            sortable: false,
            value: null,
            class: ["primary--text"],
            width: "30%"
          },
          {
            text: vm.$t("hr.training.approval_date"),
            align: "start",
            sortable: false,
            value: null,
            class: ["primary--text"],
            width: "20%"
          }
        ],
        abolisherHeaders: [
          {
            text: vm.$t("global.employee"),
            align: "start",
            sortable: false,
            value: null,
            class: ["primary--text"],
            width: "30%"
          },
          {
            text: vm.$t("global.description"),
            align: "start",
            sortable: false,
            value: null,
            class: ["primary--text"],
            width: "20%"
          },
          {
            text: vm.$t("hr.training.cancelled_date"),
            align: "start",
            sortable: false,
            value: null,
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      isRequestCancelButtonDisabled(status) {
        return ["CANCELLED", "DENIED"].indexOf(status) > -1;
      },
      openRequestCancelDialog(requestId) {
        this.requestId = requestId;
        this.requestCancelDialogStatus = true;
      },
      convertAbolisherData(item) {
        return [
          {
            employee: {
              ...item.abolisher
            },
            reason: item.abolishReason,
            eventDateTime: item.abolishDateTime
          }
        ];
      },
      getStatus(process) {
        if (process.nextApprover) {
          return "PENDING";
        } else if (process.approved === null) {
          return "NOT_COME";
        } else if (process.approved) {
          return "APPROVED";
        } else if (!process.approved) {
          return "DENIED";
        }
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: TRAINING_REQUESTS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.toPaginationCriteria(this.table.options)
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data = data.filterWithPaginateTrainingRequests.content || [];
              this.table.totalItems = data.filterWithPaginateTrainingRequests.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      onCreate() {
        this.$router.replace({ name: "training_request_create" });
      }
    }
  };
</script>

<style scoped></style>
